<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 128 128"
  >
    <path
      fill="none"
      stroke="#00796B"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M26.96 39.45c-.75-5.68-2.02-15.69-1.18-21.2s3.04-10.59 7.63-12.6c3.97-1.73 8.92.1 11.78 4.38c1.63 2.44 2.56 5.45 3.35 8.43c1.35 5.12 2.64 12.12 3.29 17.39"
    ></path>
    <path
      fill="#26A69A"
      d="M30.71 116.64L6.54 106.57l5.61-24.74l-1.2-56.38l61.16-7.18v5.34l14.55 1.84z"
    ></path>
    <path
      fill="#61DE9F"
      d="M91.85 107.44L30.7 116.7l-5.2-32.38V34.7l61.16-9.25v49.61z"
    ></path>
    <path
      fill="#263238"
      d="M25.5 34.7v-5.58l-14.55-3.67l61.28-6.8l1.96 4.34l12.47 2.46z"
    ></path>
    <path
      fill="#00796B"
      d="M6.54 106.57c.42 0 14.48-11.15 14.48-11.15l9.68 21.28l-2.08-33.93l-3.15-48.07l-4.09 51.95l-14.84 19.92z"
    ></path>
    <path
      fill="#26A69A"
      d="m72.05 19.29l1.48 3.42l.19.45l.47.12l7.45 1.91l-55.14 8.35v-5.2l-.76-.19l-9.28-2.34l55.59-6.52m.63-1.08l-61.72 7.24l14.55 3.67v5.59l61.15-9.26l-12.21-3.14l-1.77-4.1z"
    ></path>
    <path
      fill="none"
      stroke="#61DE9F"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M43.95 38.45C43.2 32.78 35.39 11 50.34 8.22c11.3-2.1 16.29 21.97 16.95 27.25"
    ></path>
    <path
      fill="#26A69A"
      d="M45.81 40.03c-.87-2.93-1.78-8.85-1.82-9.11l2.96-.45c.01.06 1.07 6.1 2.23 8.93l-3.37.63zm23.32-3.55c-.87-2.93-1.78-8.85-1.82-9.11l2.96-.45c.01.06 1.24 6.2 2.27 8.94l-3.41.62zm-22.29 77.78l8.4-14.55l-4.01-38.79l33.57-6.53l-18 56.89z"
    ></path>
    <path
      fill="none"
      stroke="#D7578A"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M73.07 57.05s-3.78-20.07 8.81-18.74c6.85.72 6.57 16.65 6.57 16.65"
    ></path>
    <path
      fill="#D7578A"
      d="m74.46 123.99l-18.57-7.77l4.32-19.02l-.92-43.34l47-5.52v4.11l11.18 1.41z"
    ></path>
    <path
      fill="#FF9F9F"
      d="m121.46 116.88l-47 7.12l-3.99-24.89V60.98l47-7.12V92z"
    ></path>
    <path
      fill="#263238"
      d="m70.47 60.98l-3.76-2.88l-7.42-4.24l47-5.52l4.27 2.69l6.91 2.83z"
    ></path>
    <path
      fill="#D7578A"
      d="m106.11 49.37l8.05 3.97l-43.48 6.58l-8.49-5.4l43.92-5.15m.18-1.03l-47 5.52l11.18 7.12l47-7.12l-11.18-5.52z"
    ></path>
    <path
      fill="#D7578A"
      d="M86.36 64.81c-.67-2.24-1.36-6.79-1.39-6.98l2.64-.34c.01.05.68 4.63 1.3 6.74l-2.55.58zm17.89-2.75c-.67-2.24-1.36-6.79-1.39-6.98l2.61-.33c.01.05.55 4.77 1.17 6.88l-2.39.43z"
    ></path>
    <path
      fill="none"
      stroke="#FF9F9F"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M84.88 64.91c-.58-4.36-6.52-22.74 4.68-24.29c8.75-1.21 12.52 16.89 13.03 20.94"
    ></path>
    <path
      fill="#AB2C5E"
      d="m55.89 116.22l9.49-8.04L74.46 124l-3.97-24.74l.04-38.16l-1.12-.46l-3.54 38.79z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "NotoShoppingBags",
};
</script>
